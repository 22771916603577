@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .primary-button {
    @apply text-base text-white bg-blue-wartung rounded-md px-4 py-1 h-12 w-56;
  }
  .secondary-button {
    @apply text-base text-white bg-black-abs rounded-md px-4 py-1 h-12 w-56;
  }
  .outline-button {
    @apply text-base text-blue-abs bg-white border-gray-dark border-[1px] rounded-md px-4 py-1 h-12 w-56;
  }
  .confirmation-button {
    @apply text-base text-white bg-green-800 rounded-md px-4 py-1 h-12 w-56;
  }
  .alert-button {
    @apply text-base text-white bg-red rounded-md px-4 py-1 h-12 w-56;
  }
  .small-button {
    @apply h-8 w-fit !important;
  }
  .full-button {
    @apply w-full !important;
  }
}
